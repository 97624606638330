import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { About } from '../components/about'
import { DiscountSection } from '../components/discount'
import { FAQ } from '../components/faq'
import { FAQ2 } from '../components/faq2'
import { FoodCarouselSection } from '../components/food'
import { FoodCarouselSection3 } from '../components/food3'
import { Footer } from '../components/footer'
import { Header } from '../components/header'
import { Hero } from '../components/hero2'
import { HowItWork } from '../components/howitwork'
import { HowItWork2 } from '../components/howitwork-v2'
import { Loader } from '../components/loader'
import { MenuOrderInfo } from '../components/menuOrderInfo'
import { Order } from '../components/order-form'
import { Order2 } from '../components/order-form2'
import { Program } from '../components/program'
import { RedirectModal } from '../components/redirect-modal'
import { Reviews } from '../components/reviews'
import { SaleFormModal } from '../components/sale-form-modal'
import { SaleFormModal2 } from '../components/sale-form-modal2'
import SEO from '../components/seo'
import { Reviews2 } from '../components/reviews2'
import { Hero3New } from '../components/hero3-new/hero3-new'
import { FoodCarouselNovinky } from '../components/food-novinky'
import { WeDeliverInPolish } from '../components/weDeliverInPolish'
import { Quality } from '../components/quality'
import { NutritionistSection } from '../components/nutritionist'
import { NewCalcBlocks } from '../components/new-calculate-blocks'

// import { HomepageTabsProvider, homepageTabsReducer, initialState } from '../contexts/HomepageTabsContext';

// const Loader = () => (
//   <div
//     style={{
//       height: "100vh",
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       justifyContent: "center",
//     }}
//   >
//     <BounceLoader size={100} color="#3DC383" />
//   </div>
// )
const ScrollToSection = () => {
  useEffect(() => {
    scrollToHash()
  }, [])

  const scrollToHash = () => {
    if (typeof window !== 'undefined') {
      const hash = window.location.hash
      if (hash) {
        const element = document.querySelector(hash)
        if (element) {
          if (hash === '#dostawa') {
            const offset = 200
            const elementRect = element.getBoundingClientRect()
            const elementTop = elementRect.top + window.pageYOffset
            const scrollToY = elementTop - offset
            window.scrollTo({ top: scrollToY, behavior: 'smooth' })
          } else {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }
    }
  }

  return <></>
}

const NewHomepage = () => (
  <>
    <SEO title="Home" />
    {/* <ChristmasPromoModal formName="christmas-promo-form" /> */}
    {/* {!isAffiliate && <SaleFormModal formName="sale-form" />} */}
    <ScrollToSection />
    <RedirectModal />
    <SaleFormModal2 formName="Akce 2 dny" />
    {/* {!isAffiliate && <DietologFormModal formName="konzultace-dietolog" />} */}
    {/* <Hero3 /> */}
    <Hero3New />
    {/* blueghost */}
    <div id="calculator2" />
    {/* <OrderFoodOnline /> */}
    {/* <Order2 id="calculator" /> */}
    <NewCalcBlocks id="calculator" />
    <FoodCarouselNovinky />
    <HowItWork2 />
    {/* <FoodCarouselNovinky /> */}
    {/* <Advantages /> */}
    {/* <Program2 /> */}
    <MenuOrderInfo />
    {/* <HowItWork3 /> */}
    {/* <Advantages /> */}
    {/* {foodVersion === "current-version" ? (<FoodCarouselSection2 />) : (<FoodCarouselSection3 />)} */}
    {/* <FoodCarouselSection2 /> */}
    <FoodCarouselSection3 id="food-section" />
    {/* <VegeMenuBlock id="vege-menu-section" /> */}
    {/* <DeliverySection id="delivery-section" /> */}
    {/* <DeliverySection2 id="delivery-section" /> */}
    <WeDeliverInPolish id="dostawa" />
    <NutritionistSection />
    {/* <Quality /> */}
    <Reviews2 id="reviews" />
    {/* <HowDoWePrepare /> */}
    {/* <DiscountSection /> */}
    <FAQ2 />
  </>
  // </HomepageTabsProvider>
)

const OldHomepage = ({ site }) => (
  <>
    <SEO title="Home" />
    {site && <Header menuLinks={site.siteMetadata.menuLinks} />}
    <Hero />
    <Program id="programs" />
    <Order id="calculator" />
    <FoodCarouselSection />
    <About />
    <Reviews id="reviews" />
    <HowItWork />
    <FAQ id="faq" />
    {/*<PreFooter />*/}
    <Footer />
  </>
)

const IndexPage = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    window.localStorage.removeItem('deliveryPrice') // necessary for order form
  }, [])

  return (
    <>
      {/* {pageVersion === "current-version" ? (
        <OldHomepage site={site} />
      ) : pageVersion === "new-version" ? (
        <NewHomepage site={site} />
      ) : (
        <Loader />
      )} */}
      {site ? <NewHomepage site={site} /> : <Loader />}
    </>
  )
}

export default IndexPage
